@if (isLoading) {
  <div
    class="fixed inset-0 bg-opacity-25 flex flex-col justify-center items-center z-50 backdrop-blur-[2px]">
    <div
      class="loader ease-linear rounded-full border-8 border-t-8 border-transparent h-32 w-32"></div>
    <p class="text-lg font-semibold text-[#212529] mt-3">
      {{ loadingMessage }}
    </p>
  </div>
}
